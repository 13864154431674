/* eslint-disable */
var _ = {},
  ArrayProto = Array.prototype,
  ObjProto = Object.prototype,
  slice = ArrayProto.slice,
  toString = ObjProto.toString,
  hasOwnProperty = ObjProto.hasOwnProperty,
  nativeForEach = ArrayProto.forEach,
  nativeIsArray = Array.isArray,
  breaker = {}
function NormalStore(t) {
  ;(this.SensorsABTest = t), (this.para = t.para)
}
function BridgeStore(t) {
  ;(this.SensorsABTest = t), (this.para = t.para)
}
;(_.each = function (t, e, s) {
  if (null == t) return !1
  if (nativeForEach && t.forEach === nativeForEach) t.forEach(e, s)
  else if (_.isArray(t) && t.length === +t.length) {
    for (var r = 0, i = t.length; r < i; r++)
      if (r in t && e.call(s, t[r], r, t) === breaker) return !1
  } else
    for (var n in t)
      if (hasOwnProperty.call(t, n) && e.call(s, t[n], n, t) === breaker)
        return !1
}),
  (_.extend = function (t) {
    return (
      _.each(slice.call(arguments, 1), function (e) {
        for (var s in e)
          hasOwnProperty.call(e, s) && void 0 !== e[s] && (t[s] = e[s])
      }),
      t
    )
  }),
  (_.isArray =
    nativeIsArray ||
    function (t) {
      return '[object Array]' === toString.call(t)
    }),
  (_.isObject = function (t) {
    return null != t && '[object Object]' == toString.call(t)
  }),
  (_.isElement = function (t) {
    return !(!t || 1 !== t.nodeType)
  }),
  (_.isString = function (t) {
    return '[object String]' == toString.call(t)
  }),
  (_.isBoolean = function (t) {
    return '[object Boolean]' == toString.call(t)
  }),
  (_.isNumber = function (t) {
    return '[object Number]' == toString.call(t) && /[\d\.]+/.test(String(t))
  }),
  (_.unique = function (t) {
    for (var e, s = [], r = {}, i = 0; i < t.length; i++)
      (e = t[i]) in r || ((r[e] = !0), s.push(e))
    return s
  }),
  (_.decodeURIComponent = function (t) {
    var e = t
    try {
      e = decodeURIComponent(t)
    } catch (s) {
      e = t
    }
    return e
  }),
  (_.getQueryParam = function (t, e) {
    ;(e = e.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')),
      (t = _.decodeURIComponent(t))
    var s = new RegExp('[\\?&]' + e + '=([^&#]*)').exec(t)
    return null === s || (s && 'string' != typeof s[1] && s[1].length)
      ? ''
      : _.decodeURIComponent(s[1])
  }),
  (_.indexOf = function (t, e) {
    var s = t.indexOf
    if (s) return s.call(t, e)
    for (var r = 0; r < t.length; r++) if (e === t[r]) return r
    return -1
  }),
  (_.isFunction = function (t) {
    if (!t) return !1
    var e = Object.prototype.toString.call(t)
    return '[object Function]' == e || '[object AsyncFunction]' == e
  }),
  (_.isEmptyObject = function (t) {
    if (_.isObject(t)) {
      for (var e in t) if (hasOwnProperty.call(t, e)) return !1
      return !0
    }
    return !1
  }),
  (_.map = function (t, e) {
    var s = []
    return null == t
      ? s
      : Array.prototype.map && t.map === Array.prototype.map
      ? t.map(e)
      : (_.each(t, function (t, r, i) {
          s.push(e(t, r, i))
        }),
        s)
  }),
  (_.base64Decode = function (t) {
    var e = _.map(atob(t).split(''), function (t) {
      return '%' + ('00' + t.charCodeAt(0).toString(16)).slice(-2)
    })
    return decodeURIComponent(e.join(''))
  }),
  (_.getURLSearchParams = function (t) {
    for (
      var e = function (t) {
          return decodeURIComponent(t)
        },
        s = {},
        r = (t = t || '').substring(1).split('&'),
        i = 0;
      i < r.length;
      i++
    ) {
      var n = r[i].indexOf('=')
      if (-1 !== n) {
        var a = r[i].substring(0, n),
          o = r[i].substring(n + 1)
        ;(a = e(a)), (o = e(o)), (s[a] = o)
      }
    }
    return s
  }),
  (_.urlParse = function (t) {
    var e = function (t) {
      ;(this._fields = {
        Username: 4,
        Password: 5,
        Port: 7,
        Protocol: 2,
        Host: 6,
        Path: 8,
        URL: 0,
        QueryString: 9,
        Fragment: 10
      }),
        (this._values = {}),
        (this._regex = null),
        (this._regex =
          /^((\w+):\/\/)?((\w+):?(\w+)?@)?([^\/\?:]+):?(\d+)?(\/?[^\?#]+)?\??([^#]+)?#?(\w*)/),
        void 0 !== t && this._parse(t)
    }
    return (
      (e.prototype.setUrl = function (t) {
        this._parse(t)
      }),
      (e.prototype._initValues = function () {
        for (var t in this._fields) this._values[t] = ''
      }),
      (e.prototype.addQueryString = function (t) {
        if ('object' != typeof t) return !1
        var e = this._values.QueryString || ''
        for (var s in t)
          e = new RegExp(s + '[^&]+').test(e)
            ? e.replace(new RegExp(s + '[^&]+'), s + '=' + t[s])
            : '&' === e.slice(-1)
            ? e + s + '=' + t[s]
            : '' === e
            ? s + '=' + t[s]
            : e + '&' + s + '=' + t[s]
        this._values.QueryString = e
      }),
      (e.prototype.getUrl = function () {
        var t = ''
        return (
          (t += this._values.Origin),
          (t += this._values.Port ? ':' + this._values.Port : ''),
          (t += this._values.Path),
          (t += this._values.QueryString ? '?' + this._values.QueryString : ''),
          (t += this._values.Fragment ? '#' + this._values.Fragment : '')
        )
      }),
      (e.prototype.getUrl = function () {
        var t = ''
        return (
          (t += this._values.Origin),
          (t += this._values.Port ? ':' + this._values.Port : ''),
          (t += this._values.Path),
          (t += this._values.QueryString ? '?' + this._values.QueryString : '')
        )
      }),
      (e.prototype._parse = function (t) {
        this._initValues()
        var e = this._regex.exec(t)
        for (var s in (e || _.log('DPURLParser::_parse -> Invalid URL'),
        this._fields))
          'undefined' != typeof e[this._fields[s]] &&
            (this._values[s] = e[this._fields[s]])
        ;(this._values.Hostname = this._values.Host.replace(/:\d+$/, '')),
          (this._values.Origin =
            this._values.Protocol + '://' + this._values.Hostname)
      }),
      new e(t)
    )
  }),
  (_.URL = function (t) {
    var e,
      s = {}
    if (
      'function' == typeof window.URL &&
      (function () {
        try {
          return (
            'http://modernizr.com/' === new URL('http://modernizr.com/').href
          )
        } catch (t) {
          return !1
        }
      })()
    )
      (s = new URL(t)).searchParams ||
        (s.searchParams =
          ((e = _.getURLSearchParams(s.search)),
          {
            get: function (t) {
              return e[t]
            }
          }))
    else {
      !1 === /^https?:\/\/.+/.test(t) && _.log('Invalid URL')
      var r = _.urlParse(t)
      ;(s.hash = ''),
        (s.host = r._values.Host
          ? r._values.Host + (r._values.Port ? ':' + r._values.Port : '')
          : ''),
        (s.href = r._values.URL),
        (s.password = r._values.Password),
        (s.pathname = r._values.Path),
        (s.port = r._values.Port),
        (s.search = r._values.QueryString ? '?' + r._values.QueryString : ''),
        (s.username = r._values.Username),
        (s.hostname = r._values.Hostname),
        (s.protocol = r._values.Protocol ? r._values.Protocol + ':' : ''),
        (s.origin = r._values.Origin
          ? r._values.Origin + (r._values.Port ? ':' + r._values.Port : '')
          : ''),
        (s.searchParams = (function () {
          var t = _.getURLSearchParams('?' + r._values.QueryString)
          return {
            get: function (e) {
              return t[e]
            }
          }
        })())
    }
    return s
  }),
  (_.getQueryParam = function (t, e) {
    ;(e = e.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')),
      (t = _.decodeURIComponent(t))
    var s = new RegExp('[\\?&]' + e + '=([^&#]*)').exec(t)
    return null === s || (s && 'string' != typeof s[1] && s[1].length)
      ? ''
      : _.decodeURIComponent(s[1])
  }),
  (_.isEmptyObject = function (t) {
    if (_.isObject(t)) {
      for (var e in t) if (hasOwnProperty.call(t, e)) return !1
      return !0
    }
    return !1
  }),
  (_.contentLoaded = function (t, e) {
    var s = !1,
      r = !0,
      i = t.document,
      n = i.documentElement,
      a = i.addEventListener,
      o = a ? 'addEventListener' : 'attachEvent',
      l = a ? 'removeEventListener' : 'detachEvent',
      c = a ? '' : 'on',
      u = function (r) {
        ;('readystatechange' == r.type && 'complete' != i.readyState) ||
          (('load' == r.type ? t : i)[l](c + r.type, u, !1),
          !s && (s = !0) && e.call(t, r.type || r))
      },
      h = function () {
        try {
          n.doScroll('left')
        } catch (t) {
          return void setTimeout(h, 50)
        }
        u('poll')
      }
    if ('complete' == i.readyState) e.call(t, 'lazy')
    else {
      if (!a && n.doScroll) {
        try {
          r = !t.frameElement
        } catch (p) {}
        r && h()
      }
      i[o](c + 'DOMContentLoaded', u, !1),
        i[o](c + 'readystatechange', u, !1),
        t[o](c + 'load', u, !1)
    }
  }),
  (_.secCheck = {
    isHttpUrl: function (t) {
      if ('string' != typeof t) return !1
      return !1 !== /^https?:\/\/.+/.test(t) || (_.log('Invalid URL'), !1)
    },
    removeScriptProtocol: function (t) {
      if ('string' != typeof t) return ''
      for (var e = /^\s*javascript/i; e.test(t); ) t = t.replace(e, '')
      return t
    }
  }),
  (_.addEvent = function () {
    function t(e) {
      return (
        e &&
          ((e.preventDefault = t.preventDefault),
          (e.stopPropagation = t.stopPropagation),
          (e._getPath = t._getPath)),
        e
      )
    }
    ;(t._getPath = function () {
      var t = this
      return (
        this.path ||
        (this.composedPath && this.composedPath()) ||
        (function () {
          try {
            var e = t.target,
              s = [e]
            if (null === e || null === e.parentElement) return []
            for (; null !== e.parentElement; )
              (e = e.parentElement), s.unshift(e)
            return s
          } catch (r) {
            return []
          }
        })()
      )
    }),
      (t.preventDefault = function () {
        this.returnValue = !1
      }),
      (t.stopPropagation = function () {
        this.cancelBubble = !0
      })
    ;(function (e, s, r) {
      if (e && e.addEventListener)
        e.addEventListener(
          s,
          function (e) {
            ;(e._getPath = t._getPath), r.call(this, e)
          },
          !0
        )
      else {
        var i = 'on' + s,
          n = e[i]
        e[i] = (function (e, s, r) {
          return function (i) {
            if (!(i = i || t(window.event))) return undefined
            i.target = i.srcElement
            var n,
              a,
              o = !0
            return (
              'function' == typeof r && (n = r(i)),
              (a = s.call(e, i)),
              (!1 !== n && !1 !== a) || (o = !1),
              o
            )
          }
        })(e, r, n)
      }
    }.apply(null, arguments))
  }),
  (_.addSinglePageEvent = function (t) {
    var e = location.href,
      s = window.history.pushState,
      r = window.history.replaceState
    ;(window.history.pushState = function () {
      s.apply(window.history, arguments), t(e), (e = location.href)
    }),
      (window.history.replaceState = function () {
        r.apply(window.history, arguments), t(e), (e = location.href)
      })
    var i = s ? 'popstate' : 'hashchange'
    _.addEvent(window, i, function () {
      t(e), (e = location.href)
    })
  }),
  (_.trim = function (t) {
    return t.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '')
  }),
  (_.checkUrlIsMatch = function (t, e) {
    var s,
      r,
      i = _.URL(t)
    if ('STRICT' === e) return location.href === i.href
    if ('FUZZY' === e) {
      try {
        s = _.URL(location.href)
      } catch (n) {
        return _.log('url \u89e3\u6790\u5931\u8d25', n), !1
      }
      try {
        r = _.URL(t)
      } catch (n) {
        return _.log('control_url \u89e3\u6790\u5931\u8d25', n), !1
      }
      return s.host === r.host && s.pathname === r.pathname
    }
    return _.log('link_match_type\u5b57\u6bb5\u5f02\u5e38', e), !1
  }),
  (_.checkUrlIsRegexp = function (t, e) {
    var s
    try {
      s = _.URL(location.href)
    } catch (n) {
      return _.log('url \u89e3\u6790\u5931\u8d25', n), !1
    }
    try {
      var r = (e ? new RegExp(t, e) : new RegExp(t)).exec(s.href),
        i = !0
      return (
        !!r &&
        (_.each(r, function (t) {
          t || (i = !1)
        }),
        i)
      )
    } catch (n) {
      return _.log('control_link\u5b57\u6bb5\u5f02\u5e38', n), !1
    }
  }),
  (_.log = function () {
    if ('object' == typeof console && console.log) {
      _.isString(arguments[0]) &&
        (arguments[0] = 'sensorsabtest————' + arguments[0])
      try {
        return console.log.apply(console, arguments)
      } catch (t) {
        console.log(arguments[0])
      }
    }
  }),
  (_.error = function () {
    if ('object' == typeof console && console.error)
      try {
        return console.error.apply(console, arguments)
      } catch (t) {
        console.error(arguments[0])
      }
  }),
  (_.storage = {
    isSupport: function () {
      var t = !0
      try {
        var e = '__sensorsdatasupport__',
          s = 'testIsSupportStorage'
        window.localStorage.setItem(e, s),
          window.localStorage.getItem(e) !== s && (t = !1),
          window.localStorage.removeItem(e)
      } catch (r) {
        t = !1
      }
      return t
    },
    set: function (t, e) {
      this.isSupport() && window.localStorage.setItem(e, t)
    },
    get: function (t) {
      var e = null
      return this.isSupport() && (e = window.localStorage.getItem(t)), e
    }
  }),
  (NormalStore.prototype = {
    init: function (t) {
      if (!this.setPara(t)) return !1
      ;(this.bridgeState = 'ab_no_host_bridge'),
        /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)
          ? (this.SensorsABTest.state.platform = 'H5')
          : (this.SensorsABTest.state.platform = 'Web'),
        this.SensorsABTest.log(
          'A/B Testing SDK \u521d\u59cb\u5316\u6210\u529f\uff0c\u8bd5\u9a8c URL\uff1a',
          t.url
        ),
        this.SensorsABTest.checkSADebug(),
        this.SensorsABTest.store.init(this.getResultFromServer, this)
    },
    setPara: function (t) {
      if (!_.isString(t.url) || 'http' !== t.url.slice(0, 4))
        return (
          this.SensorsABTest.log(
            'A/B Testing SDK \u521d\u59cb\u5316\u5931\u8d25\uff0c\u8bf7\u4f7f\u7528\u6b63\u786e\u7684 URL\uff01'
          ),
          !1
        )
      if (
        'https' === location.protocol.slice(0, 5) &&
        'http:' === t.url.slice(0, 5)
      )
        return (
          this.SensorsABTest.log(
            'A/B Testing SDK \u521d\u59cb\u5316\u5931\u8d25\uff0chttps \u9875\u9762\u5fc5\u987b\u4f7f\u7528 https \u7684 URL'
          ),
          !1
        )
      var e = _.getQueryParam(t.url, 'project-key')
      if (!e)
        return (
          this.SensorsABTest.log(
            'A/B Testing SDK \u521d\u59cb\u5316\u5931\u8d25\uff0c\u8bf7\u4f7f\u7528\u6b63\u786e\u7684 URL\uff08\u5fc5\u987b\u5305\u542b project-key\uff09\uff01'
          ),
          !1
        )
      t.project_key = e
      var s = this.SensorsABTest.verifyStore.para(
        'A/B Testing SDK \u521d\u59cb\u5316',
        t,
        { timeout_milliseconds: 'not_essential' }
      )
      return (
        (this.SensorsABTest.para = _.extend(
          {},
          this.SensorsABTest.default_para,
          s.para
        )),
        _.isBoolean(this.SensorsABTest.para.collect_bridge_status) ||
          (this.SensorsABTest.para.collect_bridge_status = !0),
        _.isNumber(this.SensorsABTest.para.update_interval) ||
          (this.SensorsABTest.para.update_interval = 6e5),
        !0 === this.SensorsABTest.sd.para.encrypt_cookie &&
          (this.SensorsABTest.para.encrypt_cookie = !0),
        this.SensorsABTest.sd.para.sdk_id &&
          (this.SensorsABTest.state.storage.name +=
            '_' + this.SensorsABTest.sd.para.sdk_id),
        !0
      )
    },
    asyncFetch: function (t) {
      var e = this
      this.SensorsABTest.normalStore.getResultFromServer({
        para: t,
        suc: function (s) {
          if (_.isObject(s) && 'SUCCESS' === s.status) {
            var r = e.SensorsABTest.getExpResult(t)
            t.callback(r)
          } else t.callback(t.default_value)
        },
        err: function () {
          t.callback(t.default_value)
        }
      })
    },
    creatRequestData: function (t) {
      var e = ''
      _.isEmptyObject(this.SensorsABTest.sd.store._state) ||
        (e =
          this.SensorsABTest.sd.store._state._first_id ||
          this.SensorsABTest.sd.store._state.first_id ||
          this.SensorsABTest.sd.store._state._distinct_id ||
          this.SensorsABTest.sd.store._state.distinct_id)
      var s = {
        anonymous_id: e,
        platform: this.SensorsABTest.state.platform,
        abtest_lib_version: this.SensorsABTest.lib_version,
        properties: {
          $is_first_day: this.SensorsABTest.sd._.cookie.getNewUser()
        }
      }
      return (
        _.isObject(t.properties) &&
          (s.properties = _.extend({}, s.properties, t.properties)),
        this.SensorsABTest.sd.store._state.first_id &&
          (s.login_id = this.SensorsABTest.sd.store.getDistinctId()),
        s
      )
    },
    getResultFromServer: function (t) {
      var e,
        s = this,
        r = (t = _.isObject(t) ? t : {}).para || {},
        i = t.suc,
        n = t.err,
        a = this.creatRequestData(r)
      this.SensorsABTest.log(
        '\u5411\u670d\u52a1\u7aef\u53d1\u8d77\u8bd5\u9a8c\u8bf7\u6c42'
      ),
        (e = s.SensorsABTest.sd.store.getDistinctId()),
        s.SensorsABTest.sd._.ajax({
          url: s.SensorsABTest.para.url,
          type: 'POST',
          data: JSON.stringify(a),
          credentials: !1,
          contentType: 'application/json',
          timeout:
            r.timeout_milliseconds || s.SensorsABTest.para.timeout_milliseconds,
          cors: !0,
          success: function (t) {
            s.SensorsABTest.dealResponseData(t, e),
              i && i(t),
              s.SensorsABTest.fetchData.setNextFetch()
          },
          error: function (t) {
            s.SensorsABTest.log(
              '\u670d\u52a1\u7aef\u8bf7\u6c42\u53d1\u9001\u5931\u8d25',
              t
            ),
              n && n(),
              s.SensorsABTest.fetchData.setNextFetch()
          }
        })
    }
  }),
  (NormalStore.prototype.methods = {
    asyncFetchABTest: function (t) {
      if (!_.isObject(t))
        return (
          _.error(
            'asyncFetchABTest\u8c03\u7528\u5931\u8d25\uff0c\u53c2\u6570\u672a\u6b63\u786e\u914d\u7f6e'
          ),
          !1
        )
      var e = this.SensorsABTest.verifyStore.para('asyncFetchABTest', t, {
        param_name: 'essential',
        value_type: 'essential',
        default_value: 'essential',
        callback: 'essential',
        timeout_milliseconds: 'not_essential',
        properties: 'not_essential'
      })
      e.verify_success &&
        ((t = e.para), this.SensorsABTest.normalStore.asyncFetch(t))
    },
    fastFetchABTest: function (t) {
      if (!_.isObject(t))
        return (
          _.error(
            'fastFetchABTest\u8c03\u7528\u5931\u8d25\uff0c\u53c2\u6570\u672a\u6b63\u786e\u914d\u7f6e'
          ),
          !1
        )
      var e = this.SensorsABTest.verifyStore.para('fastFetchABTest', t, {
        param_name: 'essential',
        value_type: 'essential',
        default_value: 'essential',
        callback: 'essential',
        timeout_milliseconds: 'not_essential',
        properties: 'not_essential'
      })
      if (e.verify_success) {
        t = e.para
        var s = this.SensorsABTest.searchLocalExp(t.param_name)
        if (_.isObject(s)) {
          var r = this.SensorsABTest.getExpResult(t, s)
          t.callback(r)
        } else
          this.SensorsABTest.log(
            'fastFetchABTest\u7f13\u5b58\u4e2d\u672a\u8bfb\u53d6\u5230\u6570\u636e\uff0c\u53d1\u8d77\u8bf7\u6c42'
          ),
            this.SensorsABTest.normalStore.asyncFetch(t)
      }
    },
    fetchCacheABTest: function (t) {
      if (_.isObject(t)) {
        var e = this.SensorsABTest.verifyStore.para('fetchCacheABTest', t, {
          param_name: 'essential',
          value_type: 'essential',
          default_value: 'essential'
        })
        if (e.verify_success) return this.SensorsABTest.getExpResult(e.para)
      } else
        _.error(
          'fetchCacheABTest\u8c03\u7528\u5931\u8d25\uff0c\u53c2\u6570\u672a\u6b63\u786e\u914d\u7f6e'
        )
    }
  }),
  (BridgeStore.prototype = {
    init: function (t) {
      var e = this
      if (
        ((this.SensorsABTest.state.storage.name = 'sawebjssdkabtest_bridge'),
        !this.setPara(t))
      )
        return !1
      this.SensorsABTest.log('A/B Testing SDK \u521d\u59cb\u5316\u6210\u529f'),
        (this.abBridge = new this.SensorsABTest.sd.JSBridge({
          type: 'abtest',
          app_call_js: function (t) {
            try {
              t = _.base64Decode(t)
            } catch (s) {
              e.SensorsABTest.log(
                'App\u6570\u636ebase64\u89e3\u7801\u5f02\u5e38',
                t
              )
            }
            try {
              ;(t = JSON.parse(t)).message_id && this.double(t)
            } catch (s) {
              e.SensorsABTest.log('App\u6570\u636e\u89e3\u6790\u5f02\u5e38', t)
            }
          }
        })),
        _.isObject(window.SensorsData_iOS_JS_Bridge) &&
        window.SensorsData_iOS_JS_Bridge.sensorsdata_abtest_module &&
        this.abBridge.hasAppBridge()
          ? (this.SensorsABTest.bridgeState = 'ab_bridge_ok')
          : _.isObject(window.SensorsData_APP_New_H5_Bridge) &&
            _.isFunction(
              window.SensorsData_APP_New_H5_Bridge.sensorsdata_abtest_module
            ) &&
            window.SensorsData_APP_New_H5_Bridge.sensorsdata_abtest_module() &&
            this.abBridge.hasAppBridge()
          ? (this.SensorsABTest.bridgeState = 'ab_bridge_ok')
          : (this.SensorsABTest.bridgeState = 'ab_no_abtest_bridge'),
        this.SensorsABTest.store.init(this.getResultFromApp, this)
    },
    setPara: function (t) {
      var e = this.SensorsABTest.verifyStore.para(
        '\u6253\u901a\u521d\u59cb\u5316',
        t,
        { timeout_milliseconds: 'not_essential' }
      )
      return (
        (this.SensorsABTest.para = _.extend(
          {},
          this.SensorsABTest.default_para,
          e.para
        )),
        _.isBoolean(this.SensorsABTest.para.collect_bridge_status) ||
          (this.SensorsABTest.para.collect_bridge_status = !0),
        _.isNumber(this.SensorsABTest.para.update_interval) ||
          (this.SensorsABTest.para.update_interval = 6e5),
        !0 === this.SensorsABTest.sd.para.encrypt_cookie &&
          (this.SensorsABTest.para.encrypt_cookie = !0),
        this.SensorsABTest.sd.para.sdk_id &&
          (this.SensorsABTest.state.storage.name +=
            '_' + this.SensorsABTest.sd.para.sdk_id),
        !0
      )
    },
    getResultFromApp: function (t) {
      var e = this,
        s = (t = _.isObject(t) ? t : {}).para || {},
        r = t.suc,
        i = t.err,
        n =
          s.timeout_milliseconds || this.SensorsABTest.para.timeout_milliseconds
      this.SensorsABTest.log('\u5411App\u53d1\u8d77\u8bd5\u9a8c\u8bf7\u6c42'),
        'ab_bridge_ok' === e.SensorsABTest.bridgeState
          ? e.abBridge.requestToApp({
              data: {
                properties: s.properties,
                timeout: n,
                request_body: { origin_platform: 'H5' }
              },
              callback: function (t) {
                _.isObject(t) && _.isObject(t.data)
                  ? (e.SensorsABTest.log(
                      '\u6210\u529f\u83b7\u53d6\u5230 App \u7aef\u8fd4\u56de\u7684\u8bd5\u9a8c\u6570\u636e',
                      'data:',
                      t
                    ),
                    e.SensorsABTest.dealResponseData(t.data),
                    r && r(t))
                  : (e.SensorsABTest.log('App \u7aef\u8bf7\u6c42\u5931\u8d25'),
                    i && i()),
                  e.SensorsABTest.fetchData.setNextFetch()
              },
              timeout: {
                time: n,
                callback: function () {
                  e.SensorsABTest.log(
                    '\u83b7\u53d6App\u7aef\u6570\u636e\u5931\u8d25'
                  ),
                    i && i(),
                    e.SensorsABTest.fetchData.setNextFetch()
                }
              }
            })
          : i &&
            (e.SensorsABTest.log(
              'A/B Testing \u6253\u901a\u5931\u8d25\uff0c',
              e.SensorsABTest.bridgeState
            ),
            i())
    },
    asyncFetch: function (t) {
      var e = this
      this.SensorsABTest.bridgeStore.getResultFromApp({
        para: t,
        suc: function (s) {
          if (
            (_.isObject(s.properties) &&
              (t.properties = _.extend(s.properties, t.properties)),
            'SUCCESS' === s.data.status)
          ) {
            var r = e.SensorsABTest.getExpResult(t)
            t.callback(r)
          } else t.callback(t.default_value)
        },
        err: function () {
          t.callback(t.default_value)
        }
      })
    }
  }),
  (BridgeStore.prototype.methods = {
    asyncFetchABTest: function (t) {
      if (!_.isObject(t))
        return (
          _.error(
            'asyncFetchABTest\u8c03\u7528\u5931\u8d25\uff0c\u53c2\u6570\u672a\u6b63\u786e\u914d\u7f6e'
          ),
          !1
        )
      var e = this.SensorsABTest.verifyStore.para('asyncFetchABTest', t, {
        param_name: 'essential',
        value_type: 'essential',
        default_value: 'essential',
        callback: 'essential',
        timeout_milliseconds: 'not_essential',
        properties: 'not_essential'
      })
      e.verify_success &&
        ((t = e.para),
        'ab_bridge_ok' === this.SensorsABTest.bridgeState
          ? this.SensorsABTest.bridgeStore.asyncFetch(t)
          : t.callback(t.default_value))
    },
    fastFetchABTest: function (t) {
      if (!_.isObject(t))
        return (
          _.error(
            'fastFetchABTest\u8c03\u7528\u5931\u8d25\uff0c\u53c2\u6570\u672a\u6b63\u786e\u914d\u7f6e'
          ),
          !1
        )
      var e = this.SensorsABTest.verifyStore.para('fastFetchABTest', t, {
        param_name: 'essential',
        value_type: 'essential',
        default_value: 'essential',
        callback: 'essential',
        timeout_milliseconds: 'not_essential',
        properties: 'not_essential'
      })
      if (e.verify_success) {
        t = e.para
        var s = this.SensorsABTest.searchLocalExp(t.param_name)
        if (_.isObject(s)) {
          var r = this.SensorsABTest.getExpResult(t, s)
          t.callback(r)
        } else
          this.SensorsABTest.log(
            'fastFetchABTest\u7f13\u5b58\u4e2d\u672a\u8bfb\u53d6\u5230\u6570\u636e\uff0c\u53d1\u8d77\u8bf7\u6c42'
          ),
            'ab_bridge_ok' === this.SensorsABTest.bridgeState
              ? this.SensorsABTest.bridgeStore.asyncFetch(t)
              : t.callback(t.default_value)
      }
    },
    fetchCacheABTest: function (t) {
      if (_.isObject(t)) {
        var e = this.SensorsABTest.verifyStore.para('fetchCacheABTest', t, {
          param_name: 'essential',
          value_type: 'essential',
          default_value: 'essential'
        })
        if (e.verify_success) return this.SensorsABTest.getExpResult(e.para)
      } else
        _.error(
          'fetchCacheABTest\u8c03\u7528\u5931\u8d25\uff0c\u53c2\u6570\u672a\u6b63\u786e\u914d\u7f6e'
        )
    }
  })
var flag_dfm = 'dfm-enc-'
function dfmapping(t) {
  var e = 't6KJCZa5pDdQ9khoEM3Tj70fbP2eLSyc4BrsYugARqFIw1mzlGNVXOHiWvxUn8',
    s = e.length - 1,
    r = {},
    i = 0
  for (i = 0; i < e.length; i++) r[e.charAt(i)] = e.charAt(s - i)
  var n = ''
  for (i = 0; i < t.length; i++)
    t.charAt(i) in r ? (n += r[t.charAt(i)]) : (n += t.charAt(i))
  return n
}
function decrypt(t) {
  return (
    0 === t.indexOf(flag_dfm) &&
      (t = dfmapping((t = t.substring(flag_dfm.length)))),
    t
  )
}
function encrypt(t) {
  return flag_dfm + dfmapping(t)
}
function VerifyStore(t) {
  this.SensorsABTest = t
}
function getSA(t) {
  return _.isObject(t) && _.isObject(t.readyState) && t.readyState.state >= 3
    ? t
    : null
}
function listenPageState(t) {
  ;({
    visibleHandle: _.isFunction(t.visible) ? t.visible : function () {},
    hiddenHandler: _.isFunction(t.hidden) ? t.hidden : function () {},
    visibilityChange: null,
    hidden: null,
    isSupport: function () {
      return 'undefined' != typeof document[this.hidden]
    },
    init: function () {
      'undefined' != typeof document.hidden
        ? ((this.hidden = 'hidden'),
          (this.visibilityChange = 'visibilitychange'))
        : 'undefined' != typeof document.mozHidden
        ? ((this.hidden = 'mozHidden'),
          (this.visibilityChange = 'mozvisibilitychange'))
        : 'undefined' != typeof document.msHidden
        ? ((this.hidden = 'msHidden'),
          (this.visibilityChange = 'msvisibilitychange'))
        : 'undefined' != typeof document.webkitHidden &&
          ((this.hidden = 'webkitHidden'),
          (this.visibilityChange = 'webkitvisibilitychange')),
        this.listen()
    },
    listen: function () {
      if (this.isSupport()) {
        var t = this
        document.addEventListener(
          t.visibilityChange,
          function () {
            document[t.hidden] ? t.hiddenHandler() : t.visibleHandle()
          },
          1
        )
      } else
        document.addEventListener
          ? (window.addEventListener('focus', this.visibleHandle, 1),
            window.addEventListener('blur', this.hiddenHandler, 1))
          : (document.attachEvent('onfocusin', this.visibleHandle),
            document.attachEvent('onfocusout', this.hiddenHandler))
    }
  }.init())
}
function getStorageData(t) {
  var e = _.storage.get(t)
  _.isString(e) && (e = decrypt(e))
  try {
    e = JSON.parse(e)
  } catch (s) {
    _.log(s)
  }
  return e
}
function setStorageData(t, e, s) {
  ;(t = JSON.stringify(t)), s && (t = encrypt(t)), _.storage.set(t, e)
}
function Mask(t) {
  ;(this.attr_name = 'sa-abtest-' + t),
    (this.remove_timer = null),
    (this.is_added = !1),
    (this.is_abort = !1),
    (this.is_timeout = !1),
    (this.style_element = null),
    this.createMaskStyle()
}
function Link(t) {
  ;(this.SensorsABTest = t),
    (this.isFirst = !0),
    (this.start_time = null),
    (this.mask_instance = null),
    (this.is_filtered_out = !1),
    (this.para = {
      timeout: 500,
      use_mask: !0,
      pass_params: !0,
      control_link_search: 'default',
      experiment_link_search: 'default'
    })
}
function getUA() {
  var t,
    e = {},
    s = navigator.userAgent.toLowerCase()
  return (
    (t = s.match(/opera.([\d.]+)/))
      ? (e.opera = Number(t[1].split('.')[0]))
      : (t = s.match(/msie ([\d.]+)/))
      ? (e.ie = Number(t[1].split('.')[0]))
      : (t = s.match(/edge.([\d.]+)/))
      ? (e.edge = Number(t[1].split('.')[0]))
      : (t = s.match(/firefox\/([\d.]+)/))
      ? (e.firefox = Number(t[1].split('.')[0]))
      : (t = s.match(/chrome\/([\d.]+)/))
      ? (e.chrome = Number(t[1].split('.')[0]))
      : (t = s.match(/version\/([\d.]+).*safari/))
      ? (e.safari = Number(t[1].match(/^\d*.\d*/)))
      : (t = s.match(/trident\/([\d.]+)/)) && (e.ie = 11),
    e
  )
}
function validateProject() {
  if (!window.name) return !1
  try {
    var t = JSON.parse(decodeURIComponent(window.name || '')),
      e = t.is_vabtesting,
      s = t.source_url,
      r = t.link_match_type
    return (
      !(!0 !== e || !_.checkUrlIsMatch(s, r)) ||
      (_.log(
        'A/B Testing SDK \u9875\u9762\u5730\u5740\uff0c\u4e0e\u5f53\u524d\u5b9e\u9a8c URL \u4e0d\u5339\u914d\uff0c\u8bf7\u4f7f\u7528\u6b63\u786e\u7684 URL\uff01'
      ),
      !1)
    )
  } catch (i) {
    return !1
  }
}
function bindReady(t, e) {
  t = t || window
  var s = !1,
    r = !0,
    i = t.document,
    n = i.documentElement,
    a = i.addEventListener,
    o = a ? 'addEventListener' : 'attachEvent',
    l = a ? 'removeEventListener' : 'detachEvent',
    c = a ? '' : 'on',
    u = function (r) {
      ;('readystatechange' == r.type && 'complete' != i.readyState) ||
        (('load' == r.type ? t : i)[l](c + r.type, u, !1),
        !s && (s = !0) && e.call(t, r.type || r))
    },
    h = function () {
      try {
        n.doScroll('left')
      } catch (t) {
        return void setTimeout(h, 50)
      }
      u('poll')
    }
  if ('complete' == i.readyState) e.call(t, 'lazy')
  else {
    if (!a && n.doScroll) {
      try {
        r = !t.frameElement
      } catch (p) {}
      r && h()
    }
    i[o](c + 'DOMContentLoaded', u, !1),
      i[o](c + 'readystatechange', u, !1),
      t[o](c + 'load', u, !1)
  }
}
function getDomIndex(t) {
  if (!t.parentNode) return -1
  for (
    var e = 0, s = t.tagName, r = t.parentNode.children, i = 0;
    i < r.length;
    i++
  )
    if (r[i].tagName === s) {
      if (t === r[i]) return e
      e++
    }
  return -1
}
function selector(t) {
  var e = t.parentNode && 9 == t.parentNode.nodeType ? -1 : getDomIndex(t)
  return t.getAttribute &&
    t.getAttribute('id') &&
    /^[A-Za-z][-A-Za-z0-9_:.]*$/.test(t.getAttribute('id'))
    ? '#' + t.getAttribute('id')
    : t.tagName.toLowerCase() + (~e ? ':nth-of-type(' + (e + 1) + ')' : '')
}
function getDomSelector(t, e) {
  if (!t || !t.parentNode || !t.parentNode.children) return !1
  e = e && e.join ? e : []
  var s = t.nodeName.toLowerCase()
  return t && 'body' !== s && 1 == t.nodeType
    ? (e.unshift(selector(t)),
      t.getAttribute &&
      t.getAttribute('id') &&
      /^[A-Za-z][-A-Za-z0-9_:.]*$/.test(t.getAttribute('id'))
        ? e.join(' > ')
        : getDomSelector(t.parentNode, e))
    : (e.unshift('body'), e.join(' > '))
}
function getStyle(t, e) {
  return t.currentStyle
    ? t.currentStyle[e]
    : t.ownerDocument.defaultView.getComputedStyle(t, null).getPropertyValue(e)
}
function getElementStyles(t, e) {
  var s = {}
  return (
    _.each(e || [], function (e) {
      var r = e.replace(/[A-Z]+/g, function (t) {
        return '-' + t.toLowerCase()
      })
      s[e] = getStyle(t, r)
    }),
    s
  )
}
function getElementText(t) {
  var e = t.childNodes,
    s = '',
    r = !1
  return (
    _.each(e, function (t) {
      !1 === r &&
        3 === t.nodeType &&
        (_.isString(t.textContent) ||
          _.isString(t.innerText) ||
          _.isString(t.nodeValue)) &&
        ((s = _.trim(t.textContent || t.innerText || t.nodeValue || '')),
        (r = !0))
    }),
    s
  )
}
function getDomBySelector(t) {
  if (!_.isString(t)) return null
  var e,
    s = t.split('>')
  return (e = (function r(t) {
    var e,
      i = s.shift()
    if (!i) return t
    try {
      e = (function (t, e) {
        var s
        if ('body' === (t = _.trim(t)))
          return document.getElementsByTagName('body')[0]
        if (0 === t.indexOf('#'))
          (t = t.slice(1)), (s = document.getElementById(t))
        else if (t.indexOf(':nth-of-type') > -1) {
          var r = t.split(':nth-of-type')
          if (!r[0] || !r[1]) return null
          var i = r[0],
            n = r[1].match(/\(([0-9]+)\)/)
          if (!n || !n[1]) return null
          var a = Number(n[1])
          if (!(_.isElement(e) && e.children && e.children.length > 0))
            return null
          for (var o = e.children, l = 0; l < o.length; l++)
            if (
              _.isElement(o[l]) &&
              o[l].tagName.toLowerCase() === i &&
              0 == --a
            ) {
              s = o[l]
              break
            }
          if (a > 0) return null
        }
        return s || null
      })(i, t)
    } catch (n) {
      e = null
    }
    return e && _.isElement(e) ? r(e) : null
  })()) && _.isElement(e)
    ? e
    : null
}
function rgbaToRgb(t) {
  var e = t.match(/[\d.]+/g)
  return e.length >= 3 ? 'rgb(' + e[0] + ',' + e[1] + ',' + e[2] + ')' : ''
}
function changeStyle(t, e) {
  var s = getUA(),
    r = s.ie && s.ie < 9,
    i = ''
  if (
    (_.each(e, function (t, e) {
      r && t.indexOf('rgba') > -1 && (t = rgbaToRgb(t))
      var s = e.replace(/[A-Z]+/g, function (t) {
        return '-' + t.toLowerCase()
      })
      i += ';' + s + ': ' + t
    }),
    '' !== i)
  ) {
    var n,
      a,
      o,
      l = t.style,
      c = l.cssText || ''
    !c ||
      ((a = ';'),
      (o = (n = c).length - a.length) >= 0 && n.indexOf(a, o) == o) ||
      (c += ';'),
      (l.cssText = c + i)
  }
}
function changeText(t, e) {
  var s = t.childNodes,
    r = !1
  _.each(s, function (t) {
    3 === t.nodeType &&
      (r ||
        (t.textContent
          ? (t.textContent = e)
          : t.innerText
          ? (t.innerText = e)
          : (t.nodeValue = e),
        (r = !0)))
  })
}
function propsFilter(t, e) {
  var s = t.originProps,
    r = e.originProps,
    i = s.attributes,
    n = r.attributes,
    a = !0
  return (
    s.text && s.text !== r.text && (a = !1),
    i &&
      _.each(['src', 'href', 'target'], function (t) {
        i[t] && i[t] !== n[t] && (a = !1)
      }),
    a
  )
}
function changeAttribute(t, e, s) {
  t.setAttribute(e, s)
}
function getElementPropsByAttr(t, e) {
  var s = e.originProps,
    r = { attributes: {} },
    i = s.attributes
  return (
    _.each(i.style || {}, function (e, s) {
      r.attributes.style[s] = getStyle(t, s)
    }),
    Object.hasOwnProperty.call(s, 'text') &&
      (r.attributes.text = getElementText(t)),
    _.each(['src', 'href'], function (e) {
      Object.hasOwnProperty.call(i, e) && (r.attributes[e] = t[e])
    }),
    Object.hasOwnProperty.call(i, 'target') &&
      (r.attributes.target = t.getAttribute('target')),
    r
  )
}
function changeAttributes(t, e, s) {
  if ((s || (s = getElementPropsByAttr(t, e)), propsFilter(e, s))) {
    var r = e.props
    _.isObject(r) && setAttributes(t, r)
  }
}
function setAttributes(t, e) {
  if (Object.hasOwnProperty.call(e, 'attributes')) {
    var s = e.attributes
    Object.hasOwnProperty.call(s, 'style') && changeStyle(t, s.style),
      _.each(['href', 'target', 'src'], function (e) {
        Object.hasOwnProperty.call(s, e) && changeAttribute(t, e, s[e])
      })
  }
  Object.hasOwnProperty.call(e, 'text') && changeText(t, e.text)
}
function loadScript(t) {
  t = _.extend(
    {
      success: function () {},
      error: function () {},
      appendCall: function (t) {
        document.getElementsByTagName('head')[0].appendChild(t)
      }
    },
    t
  )
  var e = null
  'css' === t.type &&
    (((e = document.createElement('link')).rel = 'stylesheet'),
    (e.href = t.url)),
    'js' === t.type &&
      (((e = document.createElement('script')).async = 'async'),
      e.setAttribute('charset', 'UTF-8'),
      (e.src = t.url),
      (e.type = 'text/javascript')),
    t.id && (e.id = t.id),
    (e.onload = e.onreadystatechange =
      function () {
        ;(this.readyState &&
          'loaded' !== this.readyState &&
          'complete' !== this.readyState) ||
          (t.success(), (e.onload = e.onreadystatechange = null))
      }),
    (e.onerror = function () {
      t.error(), (e.onerror = null)
    }),
    t.appendCall(e)
}
function loadVtesting(t, e) {
  loadScript(
    _.extend(
      { success: function () {}, error: function () {}, type: 'js', url: t },
      e
    )
  )
}
function addMutationObserver(t) {
  new (0, window.MutationObserver)(t).observe(document.body, {
    childList: !0,
    subtree: !0
  })
}
function getInfoByElement(t, e) {
  var s = t.tagName.toLowerCase(),
    r = getElementStyles(t, e),
    i = {
      selector: getDomSelector(t),
      originProps: { attributes: { style: r }, text: getElementText(t) },
      type: 'text',
      tagName: s
    }
  return (
    'a' === s &&
      ((i.originProps.attributes.href = t.href),
      t.getAttribute('target') &&
        (i.originProps.attributes.target = t.getAttribute('target')),
      (i.type = 'link')),
    'img' === s && ((i.originProps.attributes.src = t.src), (i.type = 'img')),
    { originStyle: t.getAttribute('style'), eleInfo: i, ele: t }
  )
}
function restoreElement(t) {
  var e = t.eleInfo,
    s = t.ele
  if (s) {
    var r = t.originStyle
    setAttributes(s, e.originProps), (s.style.cssText = r || '')
  }
}
function Vabtesting(t) {
  ;(this.SensorsABTest = t),
    (this.enable_visualize = !1),
    (this.vabtest_url =
      location.protocol +
      '//static.sensorsdata.cn/sdk/plugin/vabtest/' +
      this.SensorsABTest.lib_version +
      '/vabtest.min.js'),
    (this.timeout = 500),
    (this.is_editor = !1),
    (this.use_mask = !0),
    (this.originData = []),
    (this.visualExp = null),
    (this.isFirst = !0),
    (this.start_time = null),
    (this.is_have_observer = !1),
    (this.imgLoadingList = []),
    (this.isTrigger = !1)
}
function SensorsABTest() {
  ;(this.lib_version = '0.1.0'),
    (this.para = {}),
    (this.default_para = {
      url: '',
      path: '',
      project_key: '',
      timeout_milliseconds: 3e3,
      update_interval: 6e5,
      collect_bridge_status: !0,
      session_limit: 6e5,
      encrypt_cookie: !1
    }),
    (this.state = { platform: '', storage: { name: 'sawebjssdkabtest' } }),
    (this.bridgeState = ''),
    (this.localdata = {}),
    (this.originalData = []),
    (this.triggerList = {}),
    (this.updateTime = null),
    (this.sd = null),
    (this.link = new Link(this)),
    (this.vabtest = new Vabtesting(this)),
    (this.normalStore = new NormalStore(this)),
    (this.bridgeStore = new BridgeStore(this)),
    (this.verifyStore = new VerifyStore(this))
}
function FetchData(t) {
  this.SensorsABTest = t
}
function Store(t) {
  this.SensorsABTest = t
}
;(VerifyStore.prototype = {
  value_type_list: ['Number', 'String', 'Object', 'Boolean'],
  valueType: function (t, e) {
    switch (e) {
      case 'Number':
        if (_.isNumber(t)) return !0
        break
      case 'String':
        if (_.isString(t)) return !0
        break
      case 'Object':
        if (_.isObject(t)) return !0
        break
      case 'Boolean':
        if (!0 === t || !1 === t) return !0
        break
      default:
        return !1
    }
    return !1
  },
  para: function (t, e, s) {
    var r = this,
      i = { verify_success: !0, para: null }
    return (
      _.each(s, function (s, n) {
        if ('essential' === s)
          switch (n) {
            case 'param_name':
              ;(_.isString(e.param_name) && e.param_name.length > 0) ||
                (_.error(
                  t +
                    '\u65b9\u6cd5\u8c03\u7528\u5931\u8d25\uff0cparam_name\u53c2\u6570\u672a\u6b63\u786e\u914d\u7f6e\uff01param_name:',
                  e.param_name
                ),
                (i.verify_success = !1))
              break
            case 'value_type':
              ;(_.isString(e.value_type) &&
                -1 !== _.indexOf(r.value_type_list, e.value_type)) ||
                (_.error(
                  t +
                    '\u65b9\u6cd5\u8c03\u7528\u5931\u8d25\uff0cvalue_type\u914d\u7f6e\u9519\u8bef',
                  e.value_type
                ),
                (i.verify_success = !1))
              break
            case 'default_value':
              'undefined' == typeof e.default_value
                ? (_.error(
                    t +
                      '\u65b9\u6cd5\u8c03\u7528\u5931\u8d25\uff0cdefault_value\u53c2\u6570\u672a\u914d\u7f6e'
                  ),
                  (i.verify_success = !1))
                : r.valueType(e.default_value, e.value_type) ||
                  (_.error(
                    t +
                      '\u65b9\u6cd5\u8c03\u7528\u5931\u8d25\uff0cdefault_value\u7c7b\u578b\u5fc5\u987b\u4e0evalue_type\u4e00\u81f4\uff01',
                    e.default_value,
                    e.value_type
                  ),
                  (i.verify_success = !1))
              break
            case 'callback':
              _.isFunction(e.callback) ||
                (_.error(
                  t +
                    '\u65b9\u6cd5\u8c03\u7528\u5931\u8d25\uff0ccallback\u53c2\u6570\u672a\u6b63\u786e\u914d\u7f6e'
                ),
                (i.verify_success = !1))
              break
            default:
              i.verify_success = !1
          }
        else if ('not_essential' === s)
          switch (n) {
            case 'timeout_milliseconds':
              ;(e.timeout_milliseconds =
                e.timeout_milliseconds ||
                r.SensorsABTest.para.timeout_milliseconds ||
                r.SensorsABTest.default_para.timeout_milliseconds),
                (!_.isNumber(e.timeout_milliseconds) ||
                  (_.isNumber(e.timeout_milliseconds) &&
                    e.timeout_milliseconds <= 0)) &&
                  (_.error(
                    'timeout_milliseconds \u53c2\u6570\u9519\u8bef',
                    e.timeout_milliseconds
                  ),
                  (e.timeout_milliseconds =
                    r.SensorsABTest.para.timeout_milliseconds)),
                e.timeout_milliseconds < 200 && (e.timeout_milliseconds = 200)
              break
            case 'properties':
              e.properties = _.isObject(e.properties) ? e.properties : {}
          }
      }),
      (i.para = e),
      i
    )
  }
}),
  (Mask.prototype = {
    createMaskStyle: function () {
      var t =
          '[' +
          this.attr_name +
          '],[' +
          this.attr_name +
          '] body{opacity:0 !important;-khtml-opacity:0 !important;-moz-opacity:0;filter:alpha(opacity=0);}',
        e = document.createElement('style')
      e.type = 'text/css'
      try {
        e.appendChild(document.createTextNode(t))
      } catch (s) {
        e.styleSheet.cssText = t
      }
      try {
        ;(this.style_element = e),
          document.getElementsByTagName('head')[0].appendChild(e)
      } catch (s) {
        _.log('error when create calls')
      }
    },
    show: function (t) {
      try {
        var e = this
        if (this.is_abort) return !1
        var s = null
        if (((s = document.getElementsByTagName('html')[0]), !_.isElement(s)))
          return
        s.setAttribute(this.attr_name, '1'),
          (this.is_added = !0),
          _.isNumber(t) &&
            (this.remove_timer = setTimeout(function () {
              ;(e.is_timeout = !0), e.remove()
            }, t))
      } catch (r) {
        _.log('error when show calls')
      }
    },
    remove: function () {
      try {
        if ((this.is_abort || (this.is_abort = !0), this.is_added)) {
          this.is_added = !1
          var t = null
          if (((t = document.getElementsByTagName('html')[0]), !_.isElement(t)))
            return
          t.removeAttribute(this.attr_name),
            this.style_element &&
              (document
                .getElementsByTagName('head')[0]
                .removeChild(this.style_element),
              (this.style_element = null))
        }
        this.remove_timer &&
          (clearTimeout(this.remove_timer), (this.remove_timer = null))
      } catch (e) {
        _.log('error when remove calls')
      }
    }
  }),
  (Link.prototype.init = function (t) {
    return (
      this.initPara(t),
      this.para
        ? _.getQueryParam(location.href, 'saSDKMultilink')
          ? ((this.is_filtered_out = !0), !1)
          : ((this.start_time = new Date().getTime()),
            this.para.use_mask && this.initMask(),
            void this.addSinglePageListener())
        : (this.SensorsABTest.log('multilink is closed'), !1)
    )
  }),
  (Link.prototype.addSinglePageListener = function () {
    var t = this
    _.addSinglePageEvent(function (e) {
      return (
        e !== location.href &&
        ((t.is_filtered_out = !1),
        (t.isFirst = !0),
        t.mask_instance && t.mask_instance.remove(),
        _.getQueryParam(location.href, 'saSDKMultilink')
          ? ((t.is_filtered_out = !0), !1)
          : ((t.start_time = new Date().getTime()),
            t.para.use_mask && t.initMask(),
            void t.resolve(t.SensorsABTest.originalData)))
      )
    })
  }),
  (Link.prototype.initMask = function () {
    ;(this.mask_instance = new Mask(
      'link_' + this.SensorsABTest.sd.para.sdk_id
    )),
      this.mask_instance.show(this.para.timeout)
  }),
  (Link.prototype.initPara = function (t) {
    if (_.isFunction(t))
      try {
        t = t()
      } catch (e) {
        return this.SensorsABTest.log('link para error!'), void (this.para = !1)
      }
    !1 === t
      ? (this.para = !1)
      : _.isObject(t) &&
        ((this.para.timeout =
          _.isNumber(t.timeout) && t.timeout >= 0 ? t.timeout : 500),
        (this.para.use_mask = _.isBoolean(t.use_mask)
          ? t.use_mask
          : this.para.use_mask),
        (this.para.control_link_search = _.isString(t.control_link_search)
          ? t.control_link_search
          : 'default'),
        (this.para.experiment_link_search = _.isString(t.experiment_link_search)
          ? t.experiment_link_search
          : 'default'),
        (this.para.pass_params = !_.isBoolean(t.pass_params) || t.pass_params))
  }),
  (Link.prototype.resolve = function (t) {
    var e = this
    if (!this.para) return !1
    if (!_.isArray(t)) return !1
    if (this.is_filtered_out) return !1
    if (!this.isFirst) return !1
    if (((this.isFirst = !1), 0 === this.para.timeout)) return !1
    if (new Date().getTime() - this.start_time > this.para.timeout)
      return (
        this.SensorsABTest.log(
          'The multilink was stopped because the request timeout'
        ),
        !1
      )
    var s = !1
    _.each(t, function (t) {
      return (
        !!_.isObject(t) &&
        !s &&
        'LINK' === t.experiment_type &&
        void (e.isTriggerLinkExp(t) && ((s = !0), e.redirectUrl(t)))
      )
    }),
      !s &&
        this.para.use_mask &&
        this.mask_instance &&
        this.mask_instance.remove()
  }),
  (Link.prototype.stopTrigger = function () {
    ;(this.isFirst = !1),
      this.SensorsABTest.log(
        'The multilink was stopped because the request failed'
      ),
      this.para.use_mask && this.mask_instance && this.mask_instance.remove()
  }),
  (Link.prototype.isTriggerLinkExp = function (t) {
    return _.isString(t.control_link) && _.isString(t.link_match_type)
      ? 'REGEXP' === t.link_match_type
        ? !!_.checkUrlIsRegexp(t.control_link, t.regexp_flags) ||
          (this.SensorsABTest.log(
            '\u591a\u94fe\u63a5\u8bd5\u9a8c\u5339\u914d\u5931\u8d25',
            t.abtest_experiment_id
          ),
          !1)
        : !!_.checkUrlIsMatch(t.control_link, t.link_match_type) ||
          (this.SensorsABTest.log(
            '\u591a\u94fe\u63a5\u8bd5\u9a8c\u5339\u914d\u5931\u8d25',
            t.abtest_experiment_id
          ),
          !1)
      : (this.SensorsABTest.log(
          '\u591a\u94fe\u63a5\u8bd5\u9a8c\u6570\u636e\u5f02\u5e38',
          t.abtest_experiment_id
        ),
        !1)
  }),
  (Link.prototype.redirectUrl = function (t) {
    if (t.experiment_link && _.isString(t.experiment_link))
      if (
        !0 !== t.is_control_group ||
        ('STRICT' !== t.link_match_type && 'REGEXP' !== t.link_match_type)
      ) {
        var e,
          s,
          r = location.href,
          i = t.experiment_link
        if ('REGEXP' === t.link_match_type && /\$\d+/.test(i)) {
          var n = t.regexp_flags
            ? new RegExp(t.control_link, t.regexp_flags)
            : new RegExp(t.control_link)
          i = r.replace(n, i)
        }
        if (
          (this.para.pass_params
            ? ((s = this.getMergedUrl(t.is_control_group, i, r)), (e = a(s)))
            : ((s = this.getMergedUrl(t.is_control_group, i)), (e = a(s))),
          e)
        )
          this.SensorsABTest.triggerHandle(t)
            ? setTimeout(function () {
                location.href = e
              }, 80)
            : setTimeout(function () {
                location.href = e
              }, 0),
            this.mask_instance &&
              this.mask_instance.remove_timer &&
              clearTimeout(this.mask_instance.remove_timer)
      } else this.SensorsABTest.triggerHandle(t)
    else
      this.SensorsABTest.log(
        '\u9875\u9762\u8df3\u8f6c\u5931\u8d25\uff0cexperiment_link\u5b57\u6bb5\u5f02\u5e38',
        t.abtest_experiment_id
      )
    function a(t) {
      return _.secCheck.isHttpUrl(t)
        ? _.secCheck.removeScriptProtocol(t)
        : (this.SensorsABTest.log('\u975e\u6cd5URL'), !1)
    }
  }),
  (Link.prototype.getMergedUrl = function (t, e, s) {
    var r = '',
      i = '',
      n = '',
      a = { parse_url: null, search: '' },
      o = { parse_url: null, search: '', hash: '' }
    function l(t) {
      var e = /([^?#]+)(\?[^#]*)?(#.*)?/.exec(t)
      if (e) return { host: e[1] || '', search: e[2] || '', hash: e[3] || '' }
    }
    if (!s || ((a.parse_url = l(s)), a.parse_url)) {
      if (((o.parse_url = l(e)), o.parse_url))
        return (
          s &&
            ('after_hash' === this.para.control_link_search
              ? (a.search = c(a.parse_url.hash).search)
              : (a.search = a.parse_url.search)),
          t
            ? 'after_hash' === this.para.control_link_search
              ? ((o.search = c(o.parse_url.hash).search),
                (o.hash = c(o.parse_url.hash).hash))
              : (o.search = o.parse_url.search)
            : 'after_hash' === this.para.experiment_link_search
            ? ((o.search = c(o.parse_url.hash).search),
              (o.hash = c(o.parse_url.hash).hash))
            : (o.search = o.parse_url.search),
          (i = s
            ? this.getSearchStr(a.search, o.search)
            : this.getSearchStr(o.search)),
          (t && 'after_hash' === this.para.control_link_search) ||
          (!t && 'after_hash' === this.para.experiment_link_search)
            ? (r =
                o.hash.length > 0
                  ? o.parse_url.host + o.parse_url.search + o.hash + i
                  : o.parse_url.search.length > 0
                  ? o.parse_url.host + o.parse_url.search + '&' + i.substring(1)
                  : o.parse_url.host + i)
            : ((n =
                'after_hash' === this.para.control_link_search
                  ? o.parse_url.hash
                  : s
                  ? o.parse_url.hash || a.parse_url.hash
                  : o.parse_url.hash),
              (r = o.parse_url.host + i + n)),
          r
        )
      this.SensorsABTest.log('url \u89e3\u6790\u5931\u8d25', e)
    } else this.SensorsABTest.log('url \u89e3\u6790\u5931\u8d25', s)
    function c(t) {
      var e = { hash: '', search: '' }
      if (!_.isString(t)) return e
      var s = t.indexOf('?')
      return (
        s > -1
          ? ((e.search = t.slice(s)), (e.hash = t.slice(0, s)))
          : (e.hash = t),
        e
      )
    }
  }),
  (Link.prototype.getSearchStr = function (t, e) {
    function s(t) {
      var e = {}
      if (!t || !t.length || 0 !== t.indexOf('?')) return e
      if (!(t = t.slice(1)).length) return e
      for (var s = t.split('&'), r = 0; r < s.length; r++)
        if (s[r].indexOf('=') < 0) e[s[r]] = null
        else {
          var i = s[r].split('=')
          e[i[0]] = i[1]
        }
      return e
    }
    var r = s(t),
      i = s(e),
      n = _.extend(r, i),
      a = ''
    if (_.isEmptyObject(n)) return '?saSDKMultilink=true'
    var o = !0
    for (var l in n)
      o ? ((a += '?'), (o = !1)) : (a += '&'),
        null !== n[l] ? (a = a + l + '=' + n[l]) : (a += l)
    return a + '&saSDKMultilink=true'
  }),
  window.MutationObserver ||
    (window.MutationObserver = (function (t) {
      function e(t) {
        ;(this._watched = []), (this._listener = t)
      }
      function s(e) {
        var s = {
          type: null,
          target: null,
          addedNodes: [],
          removedNodes: [],
          previousSibling: null,
          nextSibling: null,
          attributeName: null,
          attributeNamespace: null,
          oldValue: null
        }
        for (var r in e) d(s, r) && e[r] !== t && (s[r] = e[r])
        return s
      }
      function r(e, r) {
        var i = o(e, r)
        return function (n) {
          var c,
            u = n.length
          r.charData &&
            3 === e.nodeType &&
            e.nodeValue !== i.charData &&
            n.push(
              new s({ type: 'characterData', target: e, oldValue: i.charData })
            ),
            r.attr && i.attr && a(n, e, i.attr, r.afilter),
            (r.kids || r.descendents) &&
              (c = (function (e, r, i, n) {
                var o
                function c(t, r, i, o, l) {
                  for (
                    var c, h, p, _ = t.length - 1, d = -~((_ - l) / 2);
                    (p = t.pop());

                  )
                    (c = i[p.i]),
                      (h = o[p.j]),
                      n.kids &&
                        d &&
                        Math.abs(p.i - p.j) >= _ &&
                        (e.push(
                          s({
                            type: 'childList',
                            target: r,
                            addedNodes: [c],
                            removedNodes: [c],
                            nextSibling: c.nextSibling,
                            previousSibling: c.previousSibling
                          })
                        ),
                        d--),
                      n.attr && h.attr && a(e, c, h.attr, n.afilter),
                      n.charData &&
                        3 === c.nodeType &&
                        c.nodeValue !== h.charData &&
                        e.push(
                          s({
                            type: 'characterData',
                            target: c,
                            oldValue: h.charData
                          })
                        ),
                      n.descendents && u(c, h)
                }
                function u(r, i) {
                  for (
                    var p,
                      d,
                      g,
                      f,
                      m,
                      v,
                      S,
                      y = r.childNodes,
                      b = i.kids,
                      T = y.length,
                      A = b ? b.length : 0,
                      B = 0,
                      k = 0,
                      w = 0;
                    k < T || w < A;

                  )
                    (v = y[k]),
                      (m = b[w]),
                      (S = m && m.node),
                      v === S
                        ? (n.attr && m.attr && a(e, v, m.attr, n.afilter),
                          n.charData &&
                            m.charData !== t &&
                            v.nodeValue !== m.charData &&
                            e.push(
                              s({
                                type: 'characterData',
                                target: v,
                                oldValue: m.charData
                              })
                            ),
                          d && c(d, r, y, b, B),
                          n.descendents &&
                            (v.childNodes.length ||
                              (m.kids && m.kids.length)) &&
                            u(v, m),
                          k++,
                          w++)
                        : ((o = !0),
                          p || ((p = {}), (d = [])),
                          v &&
                            (p[(g = h(v))] ||
                              ((p[g] = !0),
                              -1 === (f = l(b, v, w))
                                ? n.kids &&
                                  (e.push(
                                    s({
                                      type: 'childList',
                                      target: r,
                                      addedNodes: [v],
                                      nextSibling: v.nextSibling,
                                      previousSibling: v.previousSibling
                                    })
                                  ),
                                  B++)
                                : d.push({ i: k, j: f })),
                            k++),
                          S &&
                            S !== y[k] &&
                            (p[(g = h(S))] ||
                              ((p[g] = !0),
                              -1 === (f = _(y, S, k))
                                ? n.kids &&
                                  (e.push(
                                    s({
                                      type: 'childList',
                                      target: i.node,
                                      removedNodes: [S],
                                      nextSibling: b[w + 1],
                                      previousSibling: b[w - 1]
                                    })
                                  ),
                                  B--)
                                : d.push({ i: f, j: w })),
                            w++))
                  d && c(d, r, y, b, B)
                }
                return u(r, i), o
              })(n, e, i, r)),
            (c || n.length !== u) && (i = o(e, r))
        }
      }
      ;(e._period = 30),
        (e.prototype = {
          observe: function (t, s) {
            for (
              var i,
                n = {
                  attr: !!(
                    s.attributes ||
                    s.attributeFilter ||
                    s.attributeOldValue
                  ),
                  kids: !!s.childList,
                  descendents: !!s.subtree,
                  charData: !(!s.characterData && !s.characterDataOldValue)
                },
                a = this._watched,
                o = 0;
              o < a.length;
              o++
            )
              a[o].tar === t && a.splice(o, 1)
            s.attributeFilter &&
              (n.afilter = p(
                s.attributeFilter,
                function (t, e) {
                  return (t[e] = !0), t
                },
                {}
              )),
              a.push({ tar: t, fn: r(t, n) }),
              this._timeout ||
                ((i = this),
                (function l() {
                  var t = i.takeRecords()
                  t.length && i._listener(t, i),
                    (i._timeout = setTimeout(l, e._period))
                })())
          },
          takeRecords: function () {
            for (var t = [], e = this._watched, s = 0; s < e.length; s++)
              e[s].fn(t)
            return t
          },
          disconnect: function () {
            ;(this._watched = []),
              clearTimeout(this._timeout),
              (this._timeout = null)
          }
        })
      var i = document.createElement('i')
      i.style.top = 0
      var n = (i = 'null' != i.attributes.style.value)
        ? function (t, e) {
            return e.value
          }
        : function (t, e) {
            return 'style' !== e.name ? e.value : t.style.cssText
          }
      function a(t, e, r, i) {
        for (var a, o, l = {}, c = e.attributes, u = c.length; u--; )
          (o = (a = c[u]).name),
            (i && !d(i, o)) ||
              (n(e, a) !== r[o] &&
                t.push(
                  s({
                    type: 'attributes',
                    target: e,
                    attributeName: o,
                    oldValue: r[o],
                    attributeNamespace: a.namespaceURI
                  })
                ),
              (l[o] = !0))
        for (o in r)
          l[o] ||
            t.push(
              s({
                target: e,
                type: 'attributes',
                attributeName: o,
                oldValue: r[o]
              })
            )
      }
      function o(t, e) {
        var s = !0
        return (function r(t) {
          var i = { node: t }
          return (
            !e.charData || (3 !== t.nodeType && 8 !== t.nodeType)
              ? (e.attr &&
                  s &&
                  1 === t.nodeType &&
                  (i.attr = p(
                    t.attributes,
                    function (s, r) {
                      return (
                        (e.afilter && !e.afilter[r.name]) ||
                          (s[r.name] = n(t, r)),
                        s
                      )
                    },
                    {}
                  )),
                s &&
                  (e.kids || e.charData || (e.attr && e.descendents)) &&
                  (i.kids = (function (t, e) {
                    for (var s = [], r = 0; r < t.length; r++)
                      s[r] = e(t[r], r, t)
                    return s
                  })(t.childNodes, r)),
                (s = e.descendents))
              : (i.charData = t.nodeValue),
            i
          )
        })(t)
      }
      function l(t, e, s) {
        return _(t, e, s, 'node')
      }
      var c = 1,
        u = 'mo_id'
      function h(t) {
        try {
          return t.id || (t[u] = t[u] || c++)
        } catch (e) {
          try {
            return t.nodeValue
          } catch (s) {
            return c++
          }
        }
      }
      function p(t, e, s) {
        for (var r = 0; r < t.length; r++) s = e(s, t[r], r, t)
        return s
      }
      function _(t, e, s, r) {
        for (; s < t.length; s++) if ((r ? t[s][r] : t[s]) === e) return s
        return -1
      }
      function d(e, s) {
        return e[s] !== t
      }
      return e
    })(void 0)),
  Function.prototype.bind ||
    (Function.prototype.bind = function (t) {
      if ('function' != typeof this)
        throw new TypeError(
          'Function.prototype.bind - what is trying to be bound is not callable'
        )
      var e = Array.prototype.slice.call(arguments, 1),
        s = this,
        r = function () {},
        i = function () {
          return s.apply(
            this instanceof r && t ? this : t,
            e.concat(Array.prototype.slice.call(arguments))
          )
        }
      return (r.prototype = this.prototype), (i.prototype = new r()), i
    }),
  (Vabtesting.prototype.init = function (t) {
    var e = this
    this.initPara(t),
      !1 !== this.enable_visualize &&
        (window.self !== window.top && validateProject()
          ? ((this.is_editor = !0),
            document.getElementById('sa-abtesting-vabtest-script') ||
              loadVtesting(this.vabtest_url, {
                id: 'sa-abtesting-vabtest-script',
                success: function () {
                  setTimeout(function () {
                    e.SensorsABTest.sd.use('VABTest', {
                      SensorsABTest: e.SensorsABTest
                    })
                  }, 0)
                }
              }))
          : ((this.start_time = new Date().getTime()),
            this.use_mask && this.initMask(),
            this.addSinglePageListener()))
  }),
  (Vabtesting.prototype.initPara = function (t) {
    if (_.isFunction(t))
      try {
        t = t()
      } catch (s) {
        return this.SensorsABTest.log('ABTesting SDK  !'), void (this.para = !1)
      }
    if (_.isObject(t)) {
      Object.prototype.hasOwnProperty.call(t, 'enable_visualize') &&
      _.isBoolean(t.enable_visualize)
        ? (this.enable_visualize = t.enable_visualize)
        : this.SensorsABTest.log(
            'ABTesting SDK \u521d\u59cb\u5316\u53c2\u6570 enable_visualize \u683c\u5f0f\u4e0d\u6b63\u786e\u3002 \u7c7b\u578b\u5e94\u4e3a Boolean'
          ),
        (this.use_mask = _.isBoolean(t.use_mask) ? t.use_mask : this.use_mask),
        (this.timeout =
          _.isNumber(t.timeout) && t.timeout >= 0 ? t.timeout : this.timeout)
      var e = _.isString(t.vabtest_url) ? t.vabtest_url : ''
      e &&
        ('https:' === location.protocol
          ? 'https:' === e.slice(0, 6)
            ? (this.vabtest_url = e)
            : this.SensorsABTest.log(
                'ABTesting SDK \u521d\u59cb\u5316\u53c2\u6570 vabtest_url \u534f\u8bae\u5934\u5e94\u8bbe\u7f6e\u4e3a https'
              )
          : 'http:' === e.slice(0, 5) || 'https:' === e.slice(0, 6)
          ? (this.vabtest_url = e)
          : this.SensorsABTest.log(
              'ABTesting SDK \u521d\u59cb\u5316\u53c2\u6570 vabtest_url \u5e94\u8bbe\u7f6e\u4e3a\u6709\u6548 URL'
            ))
    } else this.para = !1
  }),
  (Vabtesting.prototype.resolve = function (t) {
    if (
      !1 !== this.enable_visualize &&
      !0 !== this.is_editor &&
      !1 !== this.isFirst
    ) {
      if (new Date().getTime() - this.start_time > this.timeout)
        return (
          this.SensorsABTest.log(
            '\u8bf7\u6c42\u5b9e\u9a8c\u7ed3\u679c\u8d85\u65f6\uff0cA/B \u53ef\u89c6\u5316\u5b9e\u9a8c\u5931\u8d25\uff01'
          ),
          !1
        )
      var e = this
      this.isFirst = !1
      var s = !1
      _.each(t, function (t) {
        if (!_.isObject(t) || 'VISUAL' !== t.experiment_type) return !1
        if (s) return !1
        var r = t.experiment_value
        if (!_.isArray(r))
          try {
            ;(r = JSON.parse(t.experiment_value)), (t.experiment_value = r)
          } catch (i) {
            return !1
          }
        e.isTriggerVisualExp(t) &&
          ((s = !0), e.prepareResource(t, e.render.bind(e)), (e.visualExp = t))
      }),
        !s && this.use_mask && this.destroyMask(),
        bindReady(window, function () {
          addMutationObserver(e.observerCallback.bind(e))
        })
    }
  }),
  (Vabtesting.prototype.initMask = function () {
    ;(this.mask_instance = new Mask(
      'vabtest_' + this.SensorsABTest.sd.para.sdk_id
    )),
      this.mask_instance.show(this.timeout)
  }),
  (Vabtesting.prototype.destroyMask = function () {
    this.mask_instance && this.mask_instance.remove()
  }),
  (Vabtesting.prototype.prepareResource = function (t, e) {
    var s = this,
      r = t.experiment_value
    _.isArray(r) &&
      (_.each(r, function (r) {
        var i = r.tagName,
          n = r.props
        if ('img' === i && n && n.attributes && _.isString(n.attributes.src)) {
          var a = n.attributes.src
          s.imgLoadingList.push(a)
          var o = document.createElement('img')
          ;(o.width = 1),
            (o.height = 1),
            (o.onload = l),
            (o.onerror = l),
            (o.onabort = l),
            (o.src = n.attributes.src)
        }
        function l() {
          var r = s.imgLoadingList.indexOf(a)
          r > -1 && s.imgLoadingList.splice(r, 1),
            0 === s.imgLoadingList.length && e(t)
        }
      }),
      0 === this.imgLoadingList.length && e(t))
  }),
  (Vabtesting.prototype.render = function (t) {
    var e = this
    bindReady(window, function () {
      if (e.use_mask && e.mask_instance && !0 === e.mask_instance.is_timeout)
        e.SensorsABTest.log(
          '\u6e32\u67d3\u5b9e\u9a8c\u7ed3\u679c\u8d85\u65f6\uff0cA/B \u53ef\u89c6\u5316\u5b9e\u9a8c\u5931\u8d25\uff01'
        )
      else {
        e.isTrigger || (e.SensorsABTest.triggerHandle(t), (e.isTrigger = !0))
        var s = t.experiment_value
        _.isArray(s) &&
          _.each(s, function (t) {
            var s = getDomBySelector(t.selector)
            if (s)
              try {
                var r = getInfoByElement(s, [])
                changeAttributes(s, t, r.eleInfo), e.originData.push(r)
              } catch (i) {}
          }),
          e.use_mask && e.destroyMask()
      }
    })
  }),
  (Vabtesting.prototype.restoreElements = function () {
    _.each(this.originData, function (t) {
      restoreElement(t)
    }),
      (this.visualExp = null),
      (this.isFirst = !0),
      (this.start_time = new Date().getTime()),
      (this.originData = []),
      (this.isTrigger = !1)
  }),
  (Vabtesting.prototype.addSinglePageListener = function () {
    var t = this
    _.addSinglePageEvent(function (e) {
      if (e === location.href) return !1
      t.mask_instance && t.destroyMask(),
        (t.start_time = new Date().getTime()),
        t.use_mask && t.initMask(),
        t.restoreElements(),
        t.resolve(t.SensorsABTest.originalData)
    })
  }),
  (Vabtesting.prototype.observerCallback = function () {
    this.visualExp &&
      (_.each(this.originData, function (t) {
        restoreElement(t)
      }),
      (this.originData = []),
      this.render(this.visualExp))
  }),
  (Vabtesting.prototype.stopTrigger = function () {
    ;(this.isFirst = !1),
      this.SensorsABTest.log(
        '\u8bf7\u6c42\u5b9e\u9a8c\u7ed3\u679c\u5931\u8d25\uff0cA/B \u53ef\u89c6\u5316\u5b9e\u9a8c\u5931\u8d25\uff01'
      ),
      this.use_mask && this.destroyMask()
  }),
  (Vabtesting.prototype.isTriggerVisualExp = function (t) {
    return _.isString(t.control_link) && _.isString(t.link_match_type)
      ? !!_.checkUrlIsMatch(t.control_link, t.link_match_type) ||
          (this.SensorsABTest.log(
            '\u53ef\u89c6\u5316\u8bd5\u9a8c\u5339\u914d\u5931\u8d25',
            t.abtest_experiment_id
          ),
          !1)
      : (this.SensorsABTest.log(
          '\u53ef\u89c6\u5316\u8bd5\u9a8c\u6570\u636e\u5f02\u5e38',
          t.abtest_experiment_id
        ),
        !1)
  }),
  (SensorsABTest.prototype.asyncFetchABTest = function () {
    _.error(
      'asyncFetchABTest\u8c03\u7528\u5931\u8d25,A/B Testing\u672a\u521d\u59cb\u5316'
    )
  }),
  (SensorsABTest.prototype.fastFetchABTest = function () {
    _.error(
      'fastFetchABTest\u8c03\u7528\u5931\u8d25,A/B Testing\u672a\u521d\u59cb\u5316'
    )
  }),
  (SensorsABTest.prototype.etchCacheABTest = function () {
    _.error(
      'fetchCacheABTest\u8c03\u7528\u5931\u8d25,A/B Testing\u672a\u521d\u59cb\u5316'
    )
  }),
  (SensorsABTest.prototype.searchLocalExp = function (t) {
    return this.localdata[t] ? this.localdata[t] : null
  }),
  (SensorsABTest.prototype.getExpResult = function (t, e) {
    var s = t.default_value,
      r = e || this.searchLocalExp(t.param_name)
    return (
      _.isObject(r)
        ? _.isObject(r.js_config) &&
          (r.js_config.type === t.value_type
            ? ((s = r.js_config.value), this.triggerHandle(r, t))
            : this.log(
                '\u8bd5\u9a8c\u7ed3\u679c\u7c7b\u578b\u4e0e\u4ee3\u7801\u671f\u671b\u7c7b\u578b\u4e0d\u4e00\u81f4\uff0cparam_name\uff1a' +
                  t.param_name +
                  '\uff0c\u5f53\u524d\u8fd4\u56de\u7c7b\u578b\u4e3a\uff1a' +
                  r.js_config.type +
                  '\uff0c\u4ee3\u7801\u671f\u671b\u7c7b\u578b\u4e3a\uff1a' +
                  t.value_type
              ))
        : this.log(
            'localdata\u672a\u67e5\u8be2\u5230\u8bd5\u9a8c\u6570\u636e\uff0c\u8bd5\u9a8c\u53c2\u6570\u540d\u79f0\uff1a' +
              t.param_name
          ),
      s
    )
  }),
  (SensorsABTest.prototype.resolveVariables = function (t) {
    this.localdata = {}
    var e = this
    _.each(t, function (t) {
      if (_.isObject(t) && t.variables && _.isArray(t.variables)) {
        if (t.experiment_type && 'CODE' !== t.experiment_type) return !1
        _.each(t.variables, function (s) {
          _.isObject(s) &&
            !e.localdata[s.name] &&
            (e.localdata[s.name] = {
              abtest_experiment_id: t.abtest_experiment_id,
              abtest_experiment_group_id: t.abtest_experiment_group_id,
              is_control_group: t.is_control_group,
              is_white_list: t.is_white_list,
              value: s.value,
              type: s.type,
              js_config: e.getRelativeValue(s.value, s.type),
              experiment_type: t.experiment_type
            })
        })
      }
    })
  }),
  (SensorsABTest.prototype.getRelativeValue = function (t, e) {
    var s = this,
      r = {},
      i = {
        INTEGER: function (t) {
          var e = parseFloat(t)
          isNaN(e)
            ? s.log(
                '\u539f\u59cb\u6570\u636e INTEGER \u7c7b\u578b\u89e3\u6790\u5f02\u5e38',
                t
              )
            : ((r.value = e), (r.type = 'Number'))
        },
        STRING: function (t) {
          _.isString(t)
            ? ((r.value = t), (r.type = 'String'))
            : s.log(
                '\u539f\u59cb\u6570\u636e STRING \u7c7b\u578b\u89e3\u6790\u5f02\u5e38',
                t
              )
        },
        JSON: function (t) {
          var e = JSON.parse(t)
          _.isObject(e)
            ? ((r.value = e), (r.type = 'Object'))
            : s.log(
                '\u539f\u59cb\u6570\u636e JSON \u7c7b\u578b\u89e3\u6790\u5f02\u5e38',
                t
              )
        },
        BOOLEAN: function (t) {
          'true' === t
            ? ((r.value = !0), (r.type = 'Boolean'))
            : 'false' === t
            ? ((r.value = !1), (r.type = 'Boolean'))
            : s.log(
                '\u539f\u59cb\u6570\u636e BOOLEAN \u7c7b\u578b\u89e3\u6790\u5f02\u5e38',
                t
              )
        }
      }
    try {
      i[e]
        ? i[e](t)
        : s.log(
            '\u8bd5\u9a8c\u6570\u636e\u7c7b\u578b\u89e3\u6790\u5931\u8d25',
            e,
            t
          )
    } catch (n) {
      s.log(n, t, e)
    }
    return r
  }),
  (SensorsABTest.prototype.dealResponseData = function (t, e) {
    _.isObject(t)
      ? 'SUCCESS' === t.status
        ? _.isArray(t.results) &&
          ((this.originalData = t.results), this.updateLocalData(t.results, e))
        : 'FAILED' === t.status &&
          this.log(
            '\u83b7\u53d6\u8bd5\u9a8c\u5931\u8d25\uff1aerror_type\uff1a' +
              t.error_type +
              ',error\uff1a' +
              t.error
          )
      : this.log(
          '\u8bd5\u9a8c\u6570\u636e\u89e3\u6790\u5931\u8d25\uff0cresponse \uff1a',
          t
        )
  }),
  (SensorsABTest.prototype.updateLocalData = function (t, e) {
    this.analyzeData(t),
      this.updateStorage(e),
      this.log('\u66f4\u65b0\u8bd5\u9a8c\u6570\u636e\u6210\u529f')
  }),
  (SensorsABTest.prototype.updateStorage = function (t) {
    var e = new Date().getTime()
    setStorageData(
      {
        data: this.originalData,
        updateTime: e,
        triggerList: this.triggerList,
        distinct_id: t || this.sd.store.getDistinctId()
      },
      this.state.storage.name,
      this.para.encrypt_cookie
    ),
      (this.updateTime = e)
  }),
  (SensorsABTest.prototype.analyzeData = function (t) {
    if (!_.isArray(t))
      return (
        this.log('\u89e3\u6790——\u6570\u636e\u683c\u5f0f\u9519\u8bef', t), !1
      )
    this.link.resolve(t), this.vabtest.resolve(t), this.resolveVariables(t)
  }),
  (SensorsABTest.prototype.triggerHandle = function (t, e) {
    var s = _.isObject(e) && _.isObject(e.properties) ? e.properties : {},
      r = this.trackTestTrigger(t, s),
      i = t.abtest_experiment_id
    if (_.isString(i)) {
      var n = this.sd.store.getDistinctId()
      this.triggerList[n] && _.isArray(this.triggerList[n])
        ? (this.triggerList[n].push(i),
          (this.triggerList[n] = _.unique(this.triggerList[n])))
        : (this.triggerList[n] = [i]),
        this.updateStorage()
    }
    return r
  }),
  (SensorsABTest.prototype.trackTestTrigger = function (t, e) {
    var s = this.sd.store.getDistinctId(),
      r = !0,
      i = !0
    if (((e = _.isObject(e) ? e : {}), !t.is_white_list)) {
      this.triggerList &&
        _.isObject(this.triggerList) &&
        ('{}' !== JSON.stringify(this.triggerList) && (i = !1),
        this.triggerList[s] &&
          _.isArray(this.triggerList[s]) &&
          _.each(this.triggerList[s], function (e) {
            e === t.abtest_experiment_id && (r = !1)
          }))
      var n = {
        $abtest_experiment_id: t.abtest_experiment_id,
        $abtest_experiment_group_id: t.abtest_experiment_group_id
      }
      if (
        (this.para.collect_bridge_status &&
          (n.$sdk_bridge_status = this.bridgeState),
        i)
      ) {
        var a = 'web_abtesting:' + this.lib_version
        n.$lib_plugin_version = [a]
      }
      var o = _.extend(n, e)
      return (
        r && this.sd.track('$ABTestTrigger', o),
        this.sd.track('WebABTestTrigger', o),
        r
      )
    }
  }),
  (SensorsABTest.prototype.checkSADebug = function () {
    var t = this,
      e = _.getQueryParam(location.href, 'sensors_abtest_url'),
      s = _.getQueryParam(location.href, 'feature_code'),
      r = +_.getQueryParam(location.href, 'account_id')
    if (e.length && s.length && _.isNumber(r) && 0 !== r) {
      var i = {
        distinct_id: this.sd.store.getDistinctId(),
        feature_code: s,
        account_id: r
      }
      this.sd._.ajax({
        url: e,
        type: 'POST',
        data: JSON.stringify(i),
        credentials: !1,
        contentType: 'application/json',
        timeout: this.para.timeout_milliseconds,
        cors: !0,
        success: function () {},
        error: function (e) {
          t.log('distinct_id\u53d1\u9001\u5931\u8d25,err:', e)
        }
      })
    }
  }),
  (SensorsABTest.prototype.initMethods = function (t) {
    var e = this
    _.each(
      ['asyncFetchABTest', 'fastFetchABTest', 'fetchCacheABTest'],
      function (s) {
        e[s] = function () {
          t.methods[s].apply(t, arguments)
        }
      }
    )
  }),
  (FetchData.prototype = {
    timer: null,
    method: null,
    context: null,
    init: function (t, e) {
      ;(this.method = t), (this.context = e), this.start(!0)
    },
    getServerData: function (t) {
      var e = this
      t
        ? this.method.call(this.context, {
            suc: function (s) {
              t &&
                ('ab_bridge_ok' === e.SensorsABTest.bridgeState && (s = s.data),
                (_.isObject(s) &&
                  'SUCCESS' === s.status &&
                  _.isArray(s.results)) ||
                  (e.SensorsABTest.link.stopTrigger(),
                  e.SensorsABTest.vabtest.stopTrigger()))
            },
            err: function () {
              t &&
                (e.SensorsABTest.link.stopTrigger(),
                e.SensorsABTest.vabtest.stopTrigger())
            }
          })
        : this.method.call(this.context)
    },
    setNextFetch: function (t) {
      var e = this,
        s = t || this.SensorsABTest.para.update_interval
      this.clearFetchTimer(this.timer),
        (this.timer = setTimeout(function () {
          e.getServerData()
        }, s))
    },
    start: function (t) {
      var e = null,
        s = new Date().getTime(),
        r = getStorageData(this.SensorsABTest.state.storage.name),
        i = this.SensorsABTest.sd.store.getDistinctId()
      if (r && _.isObject(r) && r.distinct_id === i)
        if (
          ((e = r.updateTime),
          t &&
            ((this.SensorsABTest.originalData = r.data),
            e &&
              _.isNumber(e) &&
              s - e > 0 &&
              s - e < this.SensorsABTest.para.session_limit &&
              _.isObject(r.triggerList) &&
              (this.SensorsABTest.triggerList = r.triggerList),
            this.SensorsABTest.analyzeData(r.data)),
          e &&
            _.isNumber(e) &&
            s - e > 0 &&
            s - e < this.SensorsABTest.para.update_interval)
        ) {
          this.SensorsABTest.log('\u6570\u636e\u4e0d\u66f4\u65b0', e, s)
          var n = s - e,
            a = this.SensorsABTest.para.update_interval - n
          this.setNextFetch(a)
        } else
          this.SensorsABTest.log('\u7f13\u5b58\u6570\u636e\u8d85\u65f6', e, s),
            this.getServerData(t)
      else
        (e = this.SensorsABTest.updateTime) &&
        _.isNumber(e) &&
        s - e > 0 &&
        s - e < this.SensorsABTest.para.update_interval
          ? this.SensorsABTest.log('\u6570\u636e\u4e0d\u66f4\u65b0', e, s)
          : this.getServerData(t)
    },
    stop: function () {
      this.SensorsABTest.log('\u6e05\u7a7a\u62c9\u53d6\u5b9a\u65f6\u5668'),
        this.clearFetchTimer()
    },
    clearFetchTimer: function () {
      clearTimeout(this.timer), (this.timer = null)
    }
  }),
  (Store.prototype = {
    init: function (t, e) {
      var s = this
      this.SensorsABTest.fetchData.init(t, e),
        this.SensorsABTest.initMethods(e),
        listenPageState({
          visible: function () {
            s.SensorsABTest.log('\u9875\u9762\u663e\u793a'),
              s.SensorsABTest.fetchData.start()
          },
          hidden: function () {
            s.SensorsABTest.fetchData.stop()
          }
        }),
        this.SensorsABTest.sd.events.on('changeDistinctId', function (t) {
          ;(s.SensorsABTest.localdata = {}),
            (s.SensorsABTest.originalData = []),
            s.SensorsABTest.vabtest.restoreElements(),
            s.SensorsABTest.updateStorage(),
            s.SensorsABTest.fetchData.getServerData()
        }),
        this.SensorsABTest.sd.events.isReady()
    }
  }),
  (SensorsABTest.prototype.init = function (t, e) {
    return this.sd
      ? (this.log(
          'A/B Testing SDK \u91cd\u590d\u521d\u59cb\u5316\uff01\u53ea\u6709\u7b2c\u4e00\u6b21\u521d\u59cb\u5316\u6709\u6548\uff01'
        ),
        !1)
      : getSA(t)
      ? ((this.sd = t),
        _.isObject(e)
          ? (_.storage.isSupport() || this.log('localstorage\u5f02\u5e38'),
            (this.abtestingPara = e),
            (this.fetchData = new FetchData(this)),
            (this.store = new Store(this)),
            void this.initTest())
          : (this.log(
              'A/B Testing SDK \u521d\u59cb\u5316\u5931\u8d25\uff0c\u8bf7\u4f20\u5165\u6b63\u786e\u7684\u521d\u59cb\u5316\u53c2\u6570!para:',
              e
            ),
            !1))
      : (this.log(
          'A/B Testing \u521d\u59cb\u5316\u5931\u8d25,Web JS SDK \u6ca1\u6709\u521d\u59cb\u5316\u5b8c\u6210'
        ),
        !1)
  }),
  (SensorsABTest.prototype.initTest = function () {
    this.link.init(this.abtestingPara.multilink),
      this.vabtest.init(this.abtestingPara.visualize),
      this.sd.bridge.is_verify_success
        ? this.bridgeStore.init(this.abtestingPara)
        : this.normalStore.init(this.abtestingPara)
  }),
  (SensorsABTest.prototype.log = function () {
    return (
      _.isString(arguments[0]) &&
        (arguments[0] = 'sensorsabtest————' + arguments[0]),
      this.sd && this.sd.log.apply(this, arguments)
    )
  })
var instance = new SensorsABTest()
;(instance.__constructor__ = SensorsABTest),
  window.SensorsDataWebJSSDKPlugin &&
  '[object Object]' ==
    Object.prototype.toString.call(window.SensorsDataWebJSSDKPlugin)
    ? (window.SensorsDataWebJSSDKPlugin.SensorsABTest =
        window.SensorsDataWebJSSDKPlugin.SensorsABTest || instance)
    : (window.SensorsDataWebJSSDKPlugin = { SensorsABTest: instance })
export default SensorsABTest
